
import Vue from 'vue'
import axios from 'axios'
import Toasted from 'vue-toasted'
// require('../js/cart.js')
Vue.use(Toasted)

function showData () {
  alert('sadadad')
}

export const MyMixin = {

  methods: {
    pop_success (msg) {
      const toast = this.$toasted.success(msg, {
        theme: 'bubble',
        type: 'success',
        position: 'bottom-right',
        duration: 5000,
        action: {
          text: 'x',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }

      })
    },

    pop_error (msg) {
      const toast = this.$toasted.error(msg, {
        theme: 'bubble',
        type: 'error',
        position: 'bottom-right',
        duration: 5000,
        action: {
          text: 'X',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }

      })
    },

    linkdetails () {
      var ev = this

      axios.get(this.URL + 'ajax/vue.php', {
        params: {
          type: 'linksdetails'
        }
      }).then(function (response) {
        ev.Linkdetails = response.data.data
      })
    },

    addtocart (pid, pname, qty, price) {
      var ev = this
      var user_session = JSON.parse(localStorage.getItem('user'))
      if (user_session != null) {
        var uid = user_session.uid
      } else {
        var uid = null
      }
      // console.log(pid+'//'+pname+'//'+qty)
      axios.post(this.URL + 'vue.php?type=addtocart', {
        uid: uid,
        ip_address: ev.ip_address,
        name: pname,
        pid: pid,
        qty: qty,
        price: price

      }).then(function (response) {
        // ev.sliderData = response.data.data
        if (response.data.status == 'success') {
          ev.pop_success(response.data.data.msg)
          ev.myCart()
        }
        // console.log(response.data.data)
      })
    },

    myCart () {
      var ev = this
      var user_session = JSON.parse(localStorage.getItem('user'))
      console.log(user_session)
      if (user_session != null) {
        var uid = user_session.uid
      } else {
        var uid = null
      }

      axios.post(this.URL + 'ajax/vue.php?type=mycart', {
        uid: uid,
        ip_address: ev.ip_address

      }).then(function (response) {
        ev.myCartData = Object.assign({}, ev.myCartData, { mydata: response.data })
        ev.myCartData = response.data
        console.log('sdadada')
        console.log(response.data.data)
      })
    },
    mycheck () {
      alert('dsdasdasd')
    }

  }
}
