<template>
<div class="allticket">
    <Navbar />
    <div class="container-fluid page-body-wrapper">

        <SideBar />

        <div class="main-panel">

            <div class="content-wrapper">
                <div class="row">
                    <div class="col-lg-12 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h2 class="form-heading">All Tickets</h2>

                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-md-6 filter-drop">
                                                <select class="form-control" v-model="fstatus">
                                                    <option value="">Status</option>
                                                    <option value="0">Pedding</option>
                                                    <option value="1">In Progress</option>
                                                    <option value="2">Complete</option>
                                                </select>
                                            </div>

                                            <div class="col-md-6 filter-drop">
                                                <select class="form-control" v-model="fpriority">
                                                    <option value="">Priority</option>
                                                    <option value="low">Low</option>
                                                    <option value="medium">Medium</option>
                                                    <option value="high">High</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Ticket No.</th>
                                            <th>User Name</th>
                                            <th>Title</th>
                                            <th>Ticket Type</th>
                                            <th>Created</th>
                                            <th>Priority</th>
                                            <th>Status</th>

                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="alldata in AllTickets">
                                            <tr :key="alldata.id">

                                                <td>{{alldata.id}}</td>
                                                <td>{{alldata.username}}</td>
                                                <td>{{alldata.title}}</td>
                                                <td>{{alldata.ticket_type}}</td>

                                                <td>{{alldata.udate}}</td>
                                                <td>
                                                    <label class="badge badge-danger" v-if="alldata.priority=='low'">Low</label>
                                                    <label class="badge badge-warning" v-if="alldata.priority=='medium'">Medium</label>
                                                    <label class="badge badge-success" v-if="alldata.priority=='high'">High</label>
                                                </td>
                                                <td>
                                                    <label class="badge badge-danger" v-if="alldata.status==0">Pending</label>
                                                    <label class="badge badge-warning" v-if="alldata.status==1">In progress</label>
                                                    <label class="badge badge-success" v-if="alldata.status==2">Completed</label>
                                                </td>
                                                <td>

                                                    <button class="btn btn-warning" v-if="alldata.status==0" @click="edit_ticket(alldata.id)">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <router-link class="btn btn-danger" :to="`/adminviewticket/`+alldata.id">
                                                        <i class="fa fa-eye"></i>
                                                    </router-link>
                                                    <button class="btn btn-danger" @click="delete_ticket(alldata.id)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>

                                                </td>
                                            </tr>
                                        </template>

                                    </tbody>
                                </table>

                                <pagination v-model="page" :records="totalCount" :per-page="perPage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>

    </div>

</div>
</template>

<style scoped>
.filter-drop {
    padding: 5%;
}

.form-heading {
    margin-bottom: 50px;
    font-weight: bold;
    color: #1c45ef;
}

.upload-btn {
    margin: unset;
    padding: 8px;
}

.btn-mk {
    width: 20%;
}
</style>

<script>
import Navbar from '../components/Navbar'
import SideBar from '../components/SideBar'
import Footer from '../components/Footer'
import Pagination from 'vue-pagination-2';
import axios from 'axios'
export default {
    name: 'AdminAllTicket',
    components: {
        Navbar,
        SideBar,
        Footer,
        Pagination,
    },
    data() {
        return {
            uid: localStorage.getItem('uid'),
            AllTickets: [],
            page: 1,
            totalCount: 0,
            newAry: [],
            perPage: 5,

            fstatus: "",
            fpriority: "",

        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.getAllTickets();
        setInterval(() => {
            this.getAllTickets();
        }, 60000);

    },
    watch: {
        fstatus: function (vl) {
            this.getDataFilter();
        },
        fpriority: function (vl) {
            this.getDataFilter();
        },
        AllTickets: function (vl) {
            this.AllTickets = vl;
        },
        page: function (vl) {
            this.changePage();
        }

    },
    methods: {
        getDataFilter() {

            var ev = this;
            if (this.fstatus != "" || this.fpriority != "") {
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'new_file.php',
                    data: {
                        type: 'getAdminDataFilter',
                        uid: ev.uid,
                        status: ev.fstatus,
                        periority: ev.fpriority,
                    }
                }).then(function (response) {
                    console.log(response.data);
                    var data = response.data;

                    ev.AllTickets = data.alldata;
                    ev.newAry = data.alldata;
                    ev.totalCount = data.count;
                    ev.changePage();

                })

            }

        },
        changePage() {
            this.AllTickets = [];
            var preCount = (this.perPage * this.page);

            var temp = this.perPage;
            var newPP = (preCount) - temp;
            //console.log(newPP);
            for (var i = 0; i < temp; i++) {
                if (this.newAry[newPP]) {
                    this.AllTickets[i] = this.newAry[newPP];
                    newPP++;
                }

            }
        },
        getAllTickets() {
            var ev = this;
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api2.php',
                data: {
                    type: 'getAdminTickets',

                }
            }).then(function (response) {
                var data = response.data;
                console.log(data);
                if (data.count > 0) {
                    ev.AllTickets = data.alldata;
                    ev.newAry = data.alldata;
                    ev.totalCount = data.count;
                    ev.changePage();
                }

            })
        },
        edit_ticket(tid) {
            this.$router.push('/admineditticket/' + tid);
        },
        delete_ticket(tid) {

            var ev = this;

            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'new_file.php',
                data: {
                    type: 'delete_ticket',
                    tid: tid,
                }
            }).then(function (response) {
                var data = response.data;
                //console.log(response.data)

                if (data.status == "success") {
                    ev.getAllTickets();
                    ev.$toasted.global.success({
                        message: data.msg
                    });
                } else {
                    ev.$toasted.global.error({
                        message: data.msg
                    });
                }

            })

        }
    }
}
</script>
