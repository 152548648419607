<template>
    <div class="viewticket">
           <Navbar/>
         <div class="container-fluid page-body-wrapper">
     
             <SideBar/>

            <div class="main-panel">

                <div class="content-wrapper">
                    <div class="row">
                        <div class="col-lg-12 grid-margin stretch-card">
                            <div class="card">
                            <div class="card-body">
                                
                                <h2 class="form-heading">All Tickets</h2>
                                <select class="form-control sle " style="margin-bottom:20px;" v-model="status" @change="change_status">
                                    <option>Change Ticket Status</option>
                                    <option value="0"  >Pending</option>
                                    <option value="1">In Progress</option>
                                    <option value="2">Completed</option>
                                </select>
                                <table class="table">
                                    <thead>
                                        <tr>
                                        <th>Ticket No.</th>
                                        <th>Title</th>
                                        
                                        <th>Issue Type</th>

                                        
                                        </tr>
                                    </thead>

                                    <tbody>
                                        
                                        <tr>
                                            <td>{{alldata.id}}</td>
                                            <td>{{alldata.title}}</td>
                                            <td>{{alldata.ticket_type}}</td>
                                            

                                        </tr>    

                                    </tbody>
                                 </table>   

                                 <h3 style="margin-top: 10px;margin-bottom: 10px;">Description</h3>

                                 <p v-html="alldata.description"></p> 

                                <h3 v-if="alldata.attechment">Attachment</h3>

                                <img v-if="alldata.attechment" :src="$root.URL_IMAGES+'attechment/'+alldata.attechment" style="width:100%;" />


                                
                            </div>
                            </div>
                        </div>

                        <div v-if="alldata.status!=2" class="col-lg-12 grid-margin stretch-card">
                            <div class="card">
                            <div class="card-body">
                               <div class="msg-div">
                                  <div class="msg-header">
                                     <h3>Messages</h3>
                                  </div>
                                  <div class="msg-area">
                                    <template v-for="msgs in allMsg" >  
                                      <div class="msg-card" :key="msgs.id" v-if="msgs.side=='user'">
                                          <div class="row">
                                              <div class="col-12 col-12-mk">
                                                  <img data-v-41458b80="" :src="$root.URL_IMAGES+'User/'+msgUser.avatar" alt="Profile image" class="img-xs rounded-circle">
                                                   <span class="username-span">{{msgUser.username}}</span>
                                              </div>
                                              <div class="col-12">
                                                  {{msgs.msg}}
                                              </div>
                                          </div>
                                      </div>
                                      <div class="msg-card" :key="msgs.id" v-else>
                                          <div class="row">
                                              <div class="col-12 col-12-mk">
                                                  <img data-v-41458b80="" :src="$root.URL_IMAGES+'User/'+userdata.avatar" alt="Profile image" class="img-xs rounded-circle">
                                                   <span class="username-span">{{userdata.username}}</span>
                                              </div>
                                              <div class="col-12">
                                                  {{msgs.msg}}
                                              </div>
                                          </div>
                                      </div>
                                    </template>  
                                      

                                  </div>


                                  <div class="msg-sed-area">
                                      
                                        <div class="row">
                                            <div class="col-9 txt-area">
                                                <textarea class="form-control txt-area-b" placeholder="Type here..." v-model="msg" rows="5" ></textarea>
                                            </div>
                                            <div class="col-3">
                                                <input type="submit" value="Send" class="btn btn-success mr-2" @click="saveMsg" >
                                            </div>
                                            
                                        </div>
                                      
                                  </div>

                               </div>
                            </div>
                            </div>
                        </div>

                     </div>
                    
                </div>

                <Footer/>
            </div>

         </div>

    </div>
</template>

<script>
    import Navbar from '../components/Navbar'
    import SideBar from '../components/SideBar'
    import Footer from '../components/Footer'
    import axios from 'axios'
    export default {
        name: 'AdminViewTicket',
        components: {
            Navbar,
            SideBar,
            Footer,
        },
        data () {
            return {
            alldata: [],
            uid: localStorage.getItem('uid'),
            msg:null,
            current:new Date(),
            allMsg:[],
            userdata:[],
            msgUser:[],
            status:"Change Ticket Status",

            }
        },
        mounted () {
            /// /console.log(this.$gmapOptions.load.key)
        // this.ip_address = JSON.parse(localStorage.getItem('ip_address'))
            //this.getData()
            this.getUserData();
            this.getMsgUser();
            this.getAllTickets();
            window.scrollTo(0, 0)
            console.log(this.current);
            this.showMsg();
           
  
        },
        watch: {
            allMsg: function (val) {
            this.allMsg = val ;
            console.log('watch')
            },
        },
   
        methods: {
            change_status(){
                var ev=this;
                   axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'change_status',
                            tid:this.$route.params.id,
                            status:this.status,
                            
                        }
                    }).then(function (response) {
                        var data=response.data;
                        console.log(data);

                    })
                
            },
            getMsgUser(){
                var ev=this;
                   axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'getmsgUser',
                            tid:this.$route.params.id,
                            
                        }
                    }).then(function (response) {
                        var data = response.data;
                        ev.msgUser=data;

                    })
                

            },
            getUserData(){
                
                var ev=this;
                   axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'getUserData',
                            uid:this.uid,
                            
                        }
                    }).then(function (response) {
                        var data=response.data;
                        ev.userdata=data.userdata;
                       
                        

                    })
            },
            getAllTickets(){
                var ev=this;
                   axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'getTicketsById',
                            id:this.$route.params.id,
                            
                        }
                    }).then(function (response) {
                        var data=response.data;
                        console.log(data)
                        ev.alldata=data;
                       
                    })
            },
            timeDifference(current, previous) {

                var msPerMinute = 60 * 1000;
                var msPerHour = msPerMinute * 60;
                var msPerDay = msPerHour * 24;
                var msPerMonth = msPerDay * 30;
                var msPerYear = msPerDay * 365;

                var elapsed = current - previous;

                    if (elapsed < msPerMinute) {
                        return Math.round(elapsed/1000) + ' seconds ago';   
                    }

                    else if (elapsed < msPerHour) {
                        return Math.round(elapsed/msPerMinute) + ' minutes ago';   
                    }

                    else if (elapsed < msPerDay ) {
                        return Math.round(elapsed/msPerHour ) + ' hours ago';   
                    }

                    else if (elapsed < msPerMonth) {
                        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
                    }

                    else if (elapsed < msPerYear) {
                        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
                    }

                    else {
                        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
                    }
            },
            saveMsg(){
                //console.log("sdadasdasdad");
                if(this.msg=="" || this.msg==null){
                    this.$toasted.global.error({ message : 'Please Enter Message' });
                }else{
                  
                    var ev=this;
                    axios({
                                method: 'POST',
                                url: this.$root.URL_ROOT +'new_file.php',
                                data: {
                                type: 'adminSaveMsg',
                                uid:this.uid,
                                toid:this.msgUser.id,
                                udate:this.current,
                                msg:this.msg,
                                tid:this.$route.params.id,
                            }
                        }).then(function (response) {

                            var data=response.data;
                            ev.$toasted.global.success({ message : data.msg });
                            ev.showMsg();
                            ev.msg=null;
                        })


                }
            },
            showMsg(){
                 var ev=this;
                    axios({
                                method: 'POST',
                                url: this.$root.URL_ROOT +'new_file.php',
                                data: {
                                type: 'showMsg',
                                uid:this.uid,
                                tid:this.$route.params.id,
                                
                            }
                        }).then(function (response) {

                            var data =response.data;
                            ev.allMsg=data;
                            console.log(data)

                        })

            }


        }
    }
</script>
<style scoped>
    .sle{
        width: 30%;
    float: left;
    color: #1c46ef;
    font-weight: bold;
    font-size: 16px;
    }
    .username-span{
        margin-left: 10px;
    }
    .col-12-mk{
        margin-bottom: 10px;
    }
    .msg-area{
        width: 100%;
    height: 400px;
    overflow: auto;
    background-color: #e8e7e7;
    padding: 1%;
    }
    .msg-card{
        padding: 2%;
        margin-bottom: 10px;
        margin-top:10px;
    background-color: #fff;
    box-shadow: 0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%);
    }
    .mouse-thum{
        cursor: pointer;
    }
    .msg-sed-area{
        padding: 20px;
    }
    .txt-area-b{
        border: 3px solid #dee2e6;
        border-radius:10px;

    }
    .form-heading{
        margin-bottom: 50px;
        font-weight: bold;
        color:#1c45ef;
    }
</style>