import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Toasted from 'vue-toasted'
import { store } from './store/store'
import $ from 'jquery'

Vue.use(Toasted)
const error_options = {
  type: 'error',
  position: 'bottom-center',
  singleton: true,
  theme: 'bubble',
  duration: 5000,
  onClick: (e, toastObject) => {
    toastObject.goAway(0)
  }
}
const success_options = {
  type: 'success',
  singleton: true,
  position: 'bottom-center',
  duration: 5000,
  onClick: (e, toastObject) => {
    toastObject.goAway(0)
  }
}
Vue.toasted.register('error',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'Oops.. Something Went Wrong..'
    }
    // if there is a message show it with the message
    return payload.message
  },
  error_options
)
Vue.toasted.register('success',
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return 'Greate.. Proceed Successfully..'
    }
    // if there is a message show it with the message
    return payload.message
  },
  success_options
)

Vue.config.productionTip = false

new Vue({
  data: {
    //URL_ROOT: 'http://parabolainfotech.com/admin_api/admin_mss/api/',

   // URL_ROOT: 'http://help.impact-school.com/admin_mss/api/',
     
      URL_ROOT: 'https://helpdesk.jurysoftprojects.com/admin_mss/api/',
      URL_IMAGES : 'https://helpdesk.jurysoftprojects.com/admin_mss/api/uploads/',
     // URL_IMAGES: 'http://help.impact-school.com/admin_mss/api/uploads/',
    // opencagedata
    OC_API: '83e8e742fcec49fbbbcd7cfca4397a53',
    auth: { Authorization: localStorage.getItem('token') }

  },
  store,
  router,
  render: h => h(App)
}).$mount('#app')
