<template>

     <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <!----for impact
          <a class="navbar-brand brand-logo" >
            <img src="https://impact-school.com/wp-content/uploads/2020/08/sitestrap@3x.png" class="" alt="logo" /> 
            <h2 class="site-name hide">Ticket Tracker</h2>
          </a>
          --->
          <a class="navbar-brand  new-logo" >
            <img src="../assets/images/logo-dark.png" class="" alt="logo" /> 
            <h2 class="site-name hide">Ticket Tracker</h2>
          </a>
       

          <a class="navbar-brand brand-logo-mini" href="index.html">
            <img src="../assets/images/logo-mini.svg" class="hide" alt="logo" /> 
          </a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
          <ul class="navbar-nav">
            <li class="nav-item font-weight-semibold d-none d-lg-block">Help : +050 2992 709</li>
            
          </ul>
          <form class="ml-auto search-form d-none d-md-block hide" action="#">
            <div class="form-group hide">
              <input type="search" class="form-control" placeholder="Search Here">
            </div>
          </form>
          <ul class="navbar-nav ml-auto ">
            <li class="nav-item dropdown hide">
              <a class="nav-link count-indicator" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <i class="mdi mdi-bell-outline"></i>
                <span class="count">7</span>
              </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="messageDropdown">
                <a class="dropdown-item py-3">
                  <p class="mb-0 font-weight-medium float-left">You have 7 unread mails </p>
                  <span class="badge badge-pill badge-primary float-right">View all</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <img src="../assets/images/faces/face10.jpg" alt="image" class="img-sm profile-pic">
                  </div>
                  <div class="preview-item-content flex-grow py-2">
                    <p class="preview-subject ellipsis font-weight-medium text-dark">Marian Garner </p>
                    <p class="font-weight-light small-text"> The meeting is cancelled </p>
                  </div>
                </a>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <img src="../assets/images/faces/face12.jpg" alt="image" class="img-sm profile-pic">
                  </div>
                  <div class="preview-item-content flex-grow py-2">
                    <p class="preview-subject ellipsis font-weight-medium text-dark">David Grey </p>
                    <p class="font-weight-light small-text"> The meeting is cancelled </p>
                  </div>
                </a>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <img src="../assets/images/faces/face1.jpg" alt="image" class="img-sm profile-pic">
                  </div>
                  <div class="preview-item-content flex-grow py-2">
                    <p class="preview-subject ellipsis font-weight-medium text-dark">Travis Jenkins </p>
                    <p class="font-weight-light small-text"> The meeting is cancelled </p>
                  </div>
                </a>
              </div>
            </li>
            <li class="nav-item dropdown hide">
              <a class="nav-link count-indicator" id="notificationDropdown" href="#" data-toggle="dropdown">
                <i class="mdi mdi-email-outline"></i>
                <span class="count bg-success">3</span>
              </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="notificationDropdown">
                <a class="dropdown-item py-3 border-bottom">
                  <p class="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
                  <span class="badge badge-pill badge-primary float-right">View all</span>
                </a>
                <a class="dropdown-item preview-item py-3">
                  <div class="preview-thumbnail">
                    <i class="mdi mdi-alert m-auto text-primary"></i>
                  </div>
                  <div class="preview-item-content">
                    <h6 class="preview-subject font-weight-normal text-dark mb-1">Application Error</h6>
                    <p class="font-weight-light small-text mb-0"> Just now </p>
                  </div>
                </a>
                <a class="dropdown-item preview-item py-3">
                  <div class="preview-thumbnail">
                    <i class="mdi mdi-settings m-auto text-primary"></i>
                  </div>
                  <div class="preview-item-content">
                    <h6 class="preview-subject font-weight-normal text-dark mb-1">Settings</h6>
                    <p class="font-weight-light small-text mb-0"> Private message </p>
                  </div>
                </a>
                <a class="dropdown-item preview-item py-3">
                  <div class="preview-thumbnail">
                    <i class="mdi mdi-airballoon m-auto text-primary"></i>
                  </div>
                  <div class="preview-item-content">
                    <h6 class="preview-subject font-weight-normal text-dark mb-1">New user registration</h6>
                    <p class="font-weight-light small-text mb-0"> 2 days ago </p>
                  </div>
                </a>
              </div>
            </li>
            <li class="nav-item dropdown d-none d-xl-inline-block user-dropdown">
              <a class="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <img class="img-xs rounded-circle" :src="$root.URL_IMAGES+'User/'+userData.avatar" alt="Profile image"> </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                <div class="dropdown-header text-center">
                  <img class="img-md rounded-circle" :src="$root.URL_IMAGES+'User/'+userData.avatar" alt="Profile image">
                  <p class="mb-1 mt-3 font-weight-semibold">{{userData.username}}</p>
                  <p class="font-weight-light text-muted mb-0">{{userData.email}}</p>
                </div>
                 <a class="dropdown-item mc-pointer" @click="logout" >Sign Out<i class="dropdown-item-icon ti-power-off"></i></a>
              </div>
            </li>
          </ul>
          <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>

      
</template>
<script>
   
    import axios from 'axios'
    export default {
      name: 'Home',
      components: {
        
      },
      data () {
          return {
          userData: [],
          uid: localStorage.getItem('uid'),
          }
      },
      mounted () {
          this.getUserData();
         //console console.log(this.uid)
          window.scrollTo(0, 0)
      },
      methods: {
          getUserData(){
            //console.log(localStorage.getItem('uid'));
            
             var ev=this;
             axios({
               method: 'POST', 
               url: this.$root.URL_ROOT + 'new_file.php',
               data:{
                 type:'getUserData',
                 uid:this.uid,

               }
             }).then(function(response){
                var data=response.data;
                //console.log(data)
                if(data.status=="success"){
                  ev.userData=data.userdata;
                  
                }
             })
          },
          logout () {
              localStorage.removeItem('username')
              localStorage.removeItem('mobile')
              localStorage.removeItem('uid')
              localStorage.removeItem('usertype')
              localStorage.removeItem('email')

              localStorage.removeItem('user')
              localStorage.removeItem('token')
              localStorage.removeItem('latitude')
              localStorage.removeItem('longitude')
              //this.$router.go('/login')
              this.$router.push('/login');
              localStorage.removeItem('isAdmin')
          }

      }
    }
</script>

<style scoped>
.new-logo{
  background-color: #fff;
  padding-left: 3% !important;
  padding-right: 3% !important;
}
.site-name{
  font-weight: bold;
  padding-top: 15px;
}
.mc-pointer{
  cursor: pointer;
}
.brand-logo{
      background-color: #fff;
      padding: 5% !important;
}
</style>