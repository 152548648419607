<template>
    <div class="notification">
           <Navbar/>
         <div class="container-fluid page-body-wrapper">
     
             <SideBar/>

            <div class="main-panel">

                <div class="content-wrapper">
                    <div class="row">
                        <div class="col-lg-12 grid-margin stretch-card">
                            <div class="card">
                            <div class="card-body">
                                <h2 class="form-heading">All Notification</h2>
                                <table class="table">
                                <thead>
                                    <tr>
                                    <th>Username</th>
                                    <th>Message</th>
                                    <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="alldata in AllNotification">
                                        <tr :key="alldata.id">
                                            <td>{{alldata.username}}</td>
                                            <td>{{alldata.msg}}</td>
                                            <td>{{alldata.udate}}</td>
                                            
                                        </tr>
                                    </template>

                                </tbody>
                                </table>
                                 <pagination v-model="page" :records="totalCount" :per-page="perPage" />
                            </div>
                            </div>
                        </div>
                     </div>
                </div>

                <Footer/>
            </div>

         </div>

    </div>
</template>
<style scoped>
    .form-heading{
        margin-bottom: 50px;
        font-weight: bold;
        color:#1c45ef;
    }
    .upload-btn{
        margin: unset;
        padding:8px;
    }
    .btn-mk{
        width: 20%;
    }
</style>

<script>
    import Navbar from '../components/Navbar'
    import SideBar from '../components/SideBar'
    import Footer from '../components/Footer'
     import Pagination from 'vue-pagination-2';
    import axios from 'axios'
    export default {
        name: 'AllTicket',
        components: {
            Navbar,
            SideBar,
            Footer,
            Pagination
        },
        data () {
            return {
                uid: localStorage.getItem('uid'),
                AllNotification: [],
                page:1,
                totalCount:0,
                newAry:[],
                perPage:15,
            }
        },
        mounted () {
            window.scrollTo(0, 0);
            this.getAllNoti();
            this.seenNotification();

            setInterval(() => {
                this.getAllNoti();
            }, 3000);
        },
        watch:{
            AllNotification: function (vl){
                this.AllNotification=vl
            },
            page:function(vl){
              this.changePage();
            }
        },
        methods: {
            changePage(){
                 this.AllNotification=[];
                var preCount=(this.perPage*this.page);
                
                var temp=this.perPage;
                var newPP=(preCount)-temp;
                //console.log(newPP);
                for(var i=0;i<temp;i++){
                    if(this.newAry[newPP]){
                        this.AllNotification[i]=this.newAry[newPP];
                        newPP++;
                    }
                    
                }
            },
            getAllNoti(){
                var ev=this;
                   axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'AllNotification',
                            uid: ev.uid,
                        }
                    }).then(function (response) {
                        var data=response.data;
                        console.log(data);
                        
                            ev.AllNotification=data;
                            ev.totalCount=data.length;
                            ev.newAry=data;
                            ev.changePage();
                        
                    })
            },
            edit_ticket(tid){
                this.$router.push('/editticket/'+tid);
            },
            delete_ticket(tid){

               var ev=this;

                 axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'delete_ticket',
                            tid: tid,
                        }
                    }).then(function (response) {
                        var data=response.data;
                        //console.log(response.data)

                        if(data.status=="success"){
                            ev.getAllTickets();
                            ev.$toasted.global.success({ message : data.msg });
                        }else{
                            ev.$toasted.global.error({ message : data.msg });
                        }

                    })

         
            },
            seenNotification(){
                var ev=this;
                   axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'seenNotification',
                            uid: ev.uid,
                        }
                    }).then(function (response) {
                        console.log(response.data)

                    })

            }
        }
    }
</script>