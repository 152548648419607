<template>
   
            <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
                <li class="nav-item nav-profile">
                <a href="#" class="nav-link">
                    <div class="profile-image">
                    <img class="img-xs rounded-circle" :src="$root.URL_IMAGES+'User/'+userData.avatar" alt="profile image">
                    <div class="dot-indicator bg-success"></div>
                    </div>
                    <div class="text-wrapper">
                    <p class="profile-name">{{userData.username}}</p>
                    
                    </div>
                </a>
                </li>

                <li class="nav-item nav-category">Main Menu</li>

                <template v-if="uid==0">
                
                <li class="nav-item">
                    <router-link class="nav-link" to="/admindashboard">
                        <i class="icon-item fa fa-home" style="margin-right:5px;"></i>
                        <span class="menu-title">Dashboard</span>
                    </router-link>
                </li>

                
                <li class="nav-item">
                    <router-link class="nav-link" to="/adminallticket">
                        <i class="icon-item fa fa-tasks" style="margin-right:5px;"></i>
                        <span class="menu-title">All Tickets</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/admincompleteticket">
                        <i class="icon-item fa fa-check" style="margin-right:5px;"></i>
                        <span class="menu-title">Complete Tickets</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/notification">
                        <i class="icon-item fa fa-bell" style="margin-right:5px;" ></i>
                        <span class="menu-title">Notification</span>
                        <span class="count bg-success noti">{{count}}</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/profile">
                        <i class="icon-item fa fa-user" style="margin-right:5px;"></i>
                        <span class="menu-title">Profile</span>
                    </router-link>
                </li>
                </template>
                <template v-else>

                <li class="nav-item">
                    <router-link class="nav-link" to="/userdashboard">
                        <i class="icon-item fa fa-home" style="margin-right:5px;"></i>
                        <span class="menu-title">Dashboard</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/newticket">
                        <i class="icon-item fa fa-plus-square" style="margin-right:5px;"></i>
                        <span class="menu-title">Create New Ticket</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/completeticket">
                        <i class="icon-item fa fa-check" style="margin-right:5px;"></i>
                        <span class="menu-title">Complete Tickets</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/allticket">
                        <i class="icon-item fa fa-tasks" style="margin-right:5px;"></i>
                        <span class="menu-title">All Tickets</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/notification">
                        <i class=" icon-item fa fa-bell" style="margin-right:5px;" ></i>
                        <span class="menu-title">Notification</span>
                        <span class="count bg-success noti">{{count}}</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link class="nav-link" to="/profile">
                        <i class="icon-item fa fa-user" style="margin-right:5px;"></i>
                        <span class="menu-title">Profile</span>
                    </router-link>
                </li>


                </template>


        
                
                
            </ul>
            </nav>
   
</template>
<script>
   import axios from 'axios'
    export default {
      name: 'Home',
      components: {
          
      },
      data () {
          return {
          userData: [],
          uid: localStorage.getItem('uid'),
          count:null,
          }
      },
      mounted () {
          /// /console.log(this.$gmapOptions.load.key)
      // this.ip_address = JSON.parse(localStorage.getItem('ip_address'))
          this.getUserData();
          window.scrollTo(0, 0);
          this.getCount();

          
          setInterval(() => {
             // this.getCount();
          }, 3000);

      },
      methods: {
          
          getUserData(){
           // console.log(localStorage.getItem('uid'));
             var ev=this;
             axios({
               method: 'POST', 
               url: this.$root.URL_ROOT + 'new_file.php',
               data:{
                 type:'getUserData',
                 uid:this.uid,

               }
             }).then(function(response){
                var data=response.data;
                //console.log(data)
                if(data.status=="success"){
                  ev.userData=data.userdata;
                  
                }
             })
          },
          getCount(){
           // console.log(localStorage.getItem('uid'));
             var ev=this;
             axios({
               method: 'POST', 
               url: this.$root.URL_ROOT + 'new_file.php',
               data:{
                 type:'getCountNotification',
                 uid:this.uid,

               }
             }).then(function(response){
                var data=response.data;
                //console.log(data)
                ev.count=data
            
             })
          },
          getData () {
            var self = this
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                type: 'mycartdata',
                uid: this.uid,
                ip_address: this.ip_address
                }
            }).then(function (response) {
                var data = response.data
                self.cartData = data.mycartdata
                self.cartcount = data.cart_count
                self.total = data.total
                // console.log("mycartdata");
                // console.log(response)
                // console.log("dededeed");
              })
          },
          

      }
    }
</script>
<style scoped>
    .noti{
            text-align: center;
            padding: 2%;
            border-radius: 100%;
            margin-left: 10px;
    }
    .icon-item{
        font-size: 20px;
    }
</style>