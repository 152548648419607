<template>
    <div class="register">
        <div class="container-scroller">
        
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth register-bg-1 theme-one">
                <div class="row w-100">
                    <div class=" logo-div col-lg-12 ">
                             <!-- Impact
                            <img src="https://impact-school.com/wp-content/uploads/2020/08/sitestrap@3x.png" class="logo-imgs" >
                        --->
                        <img src="../assets/images/logo-dark.png" class="logo-imgs2" >
                        </div>
                    <div class="col-lg-4 mx-auto">
                        
                    <h2 class="text-center mb-4">Register</h2>

                    <div class="auto-form-wrapper">
                       
                        <div class="form-group">
                            <div class="input-group">
                            <input type="text" class="form-control" v-model="fullname" placeholder="Full Name">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                <i class="mdi mdi-check-circle-outline"></i>
                                </span>
                            </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                            <input type="email" class="form-control" v-model="email" placeholder="Email">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                <i class="mdi mdi-check-circle-outline"></i>
                                </span>
                            </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                            <input type="number" class="form-control" v-model="mobile" placeholder="Mobile No.">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                <i class="mdi mdi-check-circle-outline"></i>
                                </span>
                            </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                            <input type="password" class="form-control" v-model="password" placeholder="Password">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                <i class="mdi mdi-check-circle-outline"></i>
                                </span>
                            </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                            <input type="password" class="form-control" v-model="cpassword" placeholder="Confirm Password">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                <i class="mdi mdi-check-circle-outline"></i>
                                </span>
                            </div>
                            </div>
                        </div>

                        <div class="form-group d-flex justify-content-center hide">
                            <div class="form-check form-check-flat mt-0">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" checked=""> I agree to the terms <i class="input-helper"></i></label>
                            </div>
                        </div>

                        <div class="form-group">
                            <button class="btn btn-primary submit-btn btn-block" @click="register" >Register</button>
                        </div>

                        <div class="text-block text-center my-3">
                            <span class="text-small font-weight-semibold">Already have and account ?</span>
                            <router-link to="/login"  class="text-black text-small">Login</router-link>
                        </div>
                        
                    </div>
                    </div>
                </div>
                </div>
                <!-- content-wrapper ends -->
            </div>
            
        </div>
    </div>
</template>


<script>
    import axios from 'axios';
    export default {
        name: 'Login',
        components: {
        
        },
        data () {
            return {
                fullname:null,
                email:null,
                mobile:null,
                password:null,
                cpassword:null,
                userdata:[],
            }
        },
        mounted () {

        },
        methods: {
            register(){
                if(this.fullname==null || this.fullname==""){
                  this.$toasted.global.error({ message: 'Please Enter Full Name' })
                }else if(this.email==null || this.email==""){
                  this.$toasted.global.error({ message: 'Please Enter Email' })
                }else if(this.mobile==null || this.mobile==""){
                  this.$toasted.global.error({ message: 'Please Enter Mobile No.' })
                }else if(this.password==null || this.password==""){
                  this.$toasted.global.error({ message: 'Please Enter Password' })
                }else if(this.cpassword==null || this.cpassword==""){
                  this.$toasted.global.error({ message: 'Please Enter Confirm Password' })
                }else if(this.password!=this.cpassword){
                  this.$toasted.global.error({ message: 'Password Not Match' })
                }else{

                    var ev = this
                    axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'register',
                            fullname: ev.fullname,
                            email: ev.email,
                            mobile: ev.mobile,
                            password: ev.password,

                            }

                    }).then(function (response) {
                        var data = response.data;
                        console.log(data);
                        if(data.status=='success'){
                            ev.userdata=data.localdata;
                          
                            ev.$toasted.global.success({ message: data.msg});
                            localStorage.setItem('username', ev.userdata.username);
                            localStorage.setItem('uid', ev.userdata.uid);
                            localStorage.setItem('mobile', ev.userdata.mobile);
                            localStorage.setItem('usertype', ev.userdata.usertype);
                            ev.$router.push('/userdashboard');

                        }else{
                            ev.$toasted.global.error({ message: data.msg })
                        }

                    },"json")


                }

            },
            login(){
             if(this.username==null || this.username==""){
                  this.$toasted.global.error({ message: 'Please Enter Username' })
             }else if(this.password==null || this.password==""){
                 this.$toasted.global.error({ message: 'Please Enter Password'})
             }else{
                  var ev = this
                    axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'login',
                            username: ev.username,
                            password: ev.password,
                            }

                    }).then(function (response) {
                        var data = response.data;
                        if(data.status=='success'){
                            ev.userdata=data.localdata;
                          
                            ev.$toasted.global.success({ message: data.msg });
                            localStorage.setItem('username', ev.userdata.username);
                            localStorage.setItem('uid', ev.userdata.uid);
                            localStorage.setItem('mobile', ev.userdata.mobile);
                            localStorage.setItem('usertype', ev.userdata.usertype);
                            ev.$router.push('/userdashboard');


                        }else{
                            ev.$toasted.global.error({ message: data.msg })
                        }
                       //console.log(response);

                    },"json")
             }
            }
        }
    }

</script>

<style scoped>
.btn{
    margin:unset !important;
}
@media only screen and (max-width: 600px) {
    .logo-imgs{
        width: 100% !important;
    }
    .logo-imgs2{
        width: 100% !important;
    }
}
.logo-imgs{
    width: 40%;
}
.logo-imgs2{
    width: 15%;
}
.register-bg-1{
    background-color: #fff;
    background:unset !important;

}
.logo-div{
    margin-bottom: 20px;
    padding: 2%;
    background-color: #fff;
     margin-top:-1%;
    text-align: center;
    
}
.auth.auth-bg-1{
     background-color: #fff !important;
   background:unset !important;
}
.auto-form-wrapper{
   box-shadow:unset !important;
    border: 1px solid #69558f;
    border-radius:10px;

}
</style>