
<template>
    <div class="editticket">
           <Navbar/>
         <div class="container-fluid page-body-wrapper">
     
             <SideBar/>

            <div class="main-panel">

                <div class="content-wrapper">
                    <div class="row">
                        <div class="col-md-2 "></div>
                        <div class="col-md-8 grid-margin stretch-card">
                            <div class="card">
                            <div class="card-body">
                                <h2 class="form-heading">Edit Profile</h2>
                                
                                
                                <div class="form-group">
                                    <label for="exampleInputName1">Fullname</label>
                                    <input type="text" class="form-control" v-model="fullname" id="exampleInputName1" placeholder="Fullname">
                                </div>

                                <div class="form-group">
                                    <label for="exampleInputName1">Email</label>
                                    <input type="email" class="form-control" v-model="email" id="exampleInputName1" placeholder="Email">
                                </div>

                                <div class="form-group">
                                    <label for="exampleInputName1">Mobile</label>
                                    <input type="number" class="form-control" v-model="mobile" id="exampleInputName1" placeholder="Mobile">
                                </div>

                                

                                

                                <div class="form-group ">
                                    <label>Profile Photo</label>
                                    <input type="file" name="img[]" id="file" ref="file" v-on:change="onChangeFileUpload()" class="file-upload-default" accept="image/*" >
                                    <div class="input-group col-xs-12">
                                    <input type="text" class="form-control file-upload-info" disabled="" :value="select_file" placeholder="Upload Image ">
                                    <span class="input-group-append">
                                        <button class="file-upload-browse btn btn-info upload-btn" @click="browse_btn" type="button">Browse</button>
                                    </span>
                                    </div>
                                </div>

                                
                                

                                <button type="submit" class="btn btn-success mr-2" @click="save_profile">Submit</button>
                                
                                
                            </div>
                            </div>
                        </div>
                     </div>
                </div>

                <Footer/>
            </div>

         </div>

    </div>
</template>
<style scoped>
    .form-heading{
        margin-bottom: 50px;
        font-weight: bold;
        color:#1c45ef;
    }
    .upload-btn{
        margin: unset;
        padding:8px;
    }
</style>

<script>
    import Navbar from '../components/Navbar'
    import SideBar from '../components/SideBar'
    import Footer from '../components/Footer'
    import axios from 'axios'
    export default {
        name: 'Profile',
        components: {
            Navbar,
            SideBar,
            Footer,
        },
        data () {
            return {
                uid: localStorage.getItem('uid'),
                file:null,
                fullname:null,
                email:null,
                mobile:null,
                select_file:null,
            }
        },
        mounted () {
            window.scrollTo(0, 0)
            this.getUserDetail();
        },
        methods: {
            browse_btn(){
                this.$refs.file.click();
            },
            onChangeFileUpload(){
                this.file = this.$refs.file.files[0];
                this.select_file=this.file.name
            },
            save_profile(){
                var ev=this;
                
                if(this.fullname=="" || this.fullname==null){
                    this.$toasted.global.error({ message : 'Please Enter Fullname' });
                }else if(this.email=="" || this.email==null){
                    this.$toasted.global.error({ message : 'Please Enter Email' });
                }else if(this.mobile=="" || this.mobile==null){
                    this.$toasted.global.error({ message : 'Please Enter mobile' });
                }else {
                        let formData = new FormData();
                        formData.append('file', this.file);
                        formData.append('fullname', this.fullname);
                        formData.append('uid', this.uid);
                        formData.append('email', this.email);
                        formData.append('mobile', this.mobile);
                        formData.append('type', 'edit_profile');
                        
                        
                        axios.post(this.$root.URL_ROOT +'new_file.php',
                            formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }
                            ).then(function(response){
                               var data=response.data;
                               console.log(data)
                               if(data.status=="success"){
                                   ev.$toasted.global.success({ message : data.msg });
                                   if(ev.uid==0){
                                       //ev.$router.push('/admindashboard');
                                       ev.getUserDetail();

                                   }else{
                                       //ev.$router.push('/userdashboard');
                                       ev.getUserDetail();
                                   }
                                   

                               }else{
                                   ev.$toasted.global.error({ message : data.msg });
                               } 
                            })


                        }

            },
            getUserDetail(){
                var ev=this;
                  axios({
                            method: 'POST',
                            url: this.$root.URL_ROOT +'new_file.php',
                            data: {
                            type: 'getUserData',
                            uid:this.uid,
                            
                        }

                    }).then(function (response) {
                        console.log(response.data);
                        var data=response.data.userdata;
                        ev.fullname=data.username;
                        ev.email=data.email;
                        ev.mobile=data.mobile;
                        

                    })
            }
            
            

        }
    }
</script>